import React, {useEffect, useState, useContext} from 'react'
import { hidingHeader } from 'hiding-header'
import { Link, useNavigate, Outlet, NavLink  } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faPlusCircle, faMinusCircle, faTrashCan, faSun, faAddressCard, faMoon } from '@fortawesome/free-solid-svg-icons'
import CartContext from '../../middlewares/cart/CartContext';
import AuthContext from '../../middlewares/AuthContext';
import Swal from 'sweetalert2'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import BackButton from '../layouts/BackButton'

const Header = () => {
	const {onLogout, token, getHeaders} = useContext(AuthContext)
	const [tokenDecode, setTokenDecode] = useState(null)
    /***************************************************************************************************************************/
    const url    = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL_DEV;
    const urlapi = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URLAPI : process.env.REACT_APP_URLAPI_DEV;
    /***************************************************************************************************************************/
	const [darkmode, setdarkmode]         = useState(false);
	const { cart, countItem, deleteItem } = useContext(CartContext);
	let   navigate                        = useNavigate();
	const { t, i18n }                     = useTranslation();
	const [products, setproducts]         = useState([]);
	const [loading, setloading]           = useState(false);


	const validate = async() => { 
		const _headers = await getHeaders();
        await axios.get(`${url}auth/validate`, {headers: _headers})
                    .then(res =>{
                        console.log(res);
                    })
                    .catch(err =>{
                        onLogout();
                    })	
	}
						
	useEffect(() => {

		console.log(i18n.language )
		console.log(cart)
		const first = async() => {
			try {
				const dark_mode = await AsyncStorage.getItem('@dark_mode');
				if(dark_mode !== null) {
					// value previously stored
					if (dark_mode === 'light') {
						setdarkmode(true)
					}
					else {
						setdarkmode(false)
					}
				}else{
					setdarkmode(true)
				}
			  
			}catch (e) {
			  console.log(e)
			}	
		}

		const getProducts = async() => {
			console.log(url)
			await axios.get(`${url}products/home`)
						.then(res =>{
							setproducts(res.data.message);
							console.log(res.data);
						})
						.catch(err =>{
							console.log(err)
						})
						.then(() =>{
							setloading(true)
						});
	
		}

		if(token !== null){
			setTokenDecode(jwt_decode(token))
		}

		validate(); // Test jwt expired
		first();
		getProducts();
	}, [])
	
	const darkMode = async() => {

		// value previously stored
		if (document.documentElement.getAttribute('data-bs-theme') === 'dark') {
			document.documentElement.setAttribute('data-bs-theme','light')
			await AsyncStorage.setItem('@dark_mode', 'light')
			setdarkmode(true)
		}
		else {
			document.documentElement.setAttribute('data-bs-theme','dark')
			await AsyncStorage.setItem('@dark_mode', 'dark')
			setdarkmode(false)
		}

	}
/*************************************************************************************************************************************/

	const confirmDeleteItem = (e) => {
	
		Swal.fire({
			title: t('header.cart.delete_item'),
			text: t('header.cart.do_you'),
			showCancelButton: true,
			confirmButtonText: t('header.cart.delete'),
			customClass: {
				confirmButton: 'btn btn-outline-danger',
				cancelButton: 'btn btn-primary mx-2'
			},
			reverseButtons: true,
			buttonsStyling: false,
			position: 'top',
			showClass: {
				popup: 'animate__animated animate__fadeInDown'
			},
			  hideClass: {
				popup: 'animate__animated animate__fadeOutUp'
			}
		  }).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
			  deleteItem(e);
			}
		  })
		
	}

/*************************************************************************************************************************************/

	const logout = () => { 
	
		Swal.fire({
			title             : t('header.logout_session'),
			showCancelButton  : true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor : '#d33',
			confirmButtonText : t('header.yes_logout_session'),
			showClass         : {
				popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              	popup: 'animate__animated animate__fadeOutUp'
            }
		}).then((result) => {
			if (result.isConfirmed) {
				onLogout();
				navigate('/')
			}
		})
		
	}

/*************************************************************************************************************************************/
	useEffect(() => {
		console.log(cart)
		console.log(cart.length)
		// const container = document.querySelector('#hidingHeader');
		// hidingHeader(container);

	}, [])
/*************************************************************************************************************************************/

  return (
	<>


		<div className='main' >
			{
				process.env.REACT_APP_ENV === 'qa' &&
					<div className='position-fixed bg-danger opacity-75 fw-bold p-3 rounded dev-qa'>
						Entorno de QA
					</div>				
			}			
			
			<nav className={`navbar bg-body-tertiary navbar-expand-lg sticky-top shadow-sm border-bottom flex-column`}>
				<div className="container-fluid">

					<button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll2" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse- navbar-collapse" id="navbarScroll2">
						<div className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
							
						</div>
						<div className='d-flex align-items-center'>
							
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<Link className="navbar-brand" to="/">
						<img src='https://labeldictate.com/assets/img/Label-Dictate-Logo-V2021.png' width={80}/>
					</Link>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					
					<div className="collapse navbar-collapse" id="navbarScroll">
						<ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
							<li className="nav-item dropdown">
								<a className="nav-link dropdown-toggle mt-3" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
									{t('header.products')}
								</a>
								<ul className="dropdown-menu">

									{
										products.length > 0 &&
										products.map((e,i) => (
											<li key={`prods-${i}`}>
												<NavLink to={`/products/${e.name}`} className="dropdown-item">{e.name}</NavLink> 
											</li>
										))
									}
								</ul>
							</li>
						</ul>
						{/* <div className="d-flex"> */}
						{/* <div className='d-flex align-items-start align-items-sm-center align-items-md-center align-items-lg-center flex-column flex-sm-row flex-md-row flex-lg-row'> */}
						<div className='d-flex align-items-start align-items-sm-end align-items-md-end align-items-lg-end flex-column flex-sm-row flex-md-row flex-lg-row'>
							
							<div className='dropdown dropdown-center d-inline'>
								<button className='btn me-3 position-relative' type='button' data-bs-toggle="dropdown" data-bs-auto-close="outside" data-bs-display="static" aria-expanded="false" style={{width: '60px'}}>
									<div className='circle position-absolute'>
										<span className='text-white'>
											{
												cart.reduce((accumulator, currentValue) =>
													accumulator + currentValue.quantity, 0
												)
											}
										</span>
									</div>
									<FontAwesomeIcon icon={faCartShopping} size="2x" className='text-dark-emphasis'/>
								</button>
								<ul className="dropdown-menu dropdown-menu-lg-start dropdown-menu-md-center dropdown-menu-sm-center" style={{width: '350px', left: '-10px'}}>
									{
										cart.map((e,i) =>{
											return <li className='border-bottom mb-2' key={`header-${i}`}>
												<a className="dropdown-item" href="#!">{t('header.cart.product')}: {e.product.product_name}</a>
												<div className='px-3'>
													<span className='text-muted' href="#!">Plan: {e.product.name} - </span>
													<span className='text-muted'>{e.product.description.substring(0,25)} ...</span>
												
													<div className='row align-items-center'>
														<div className='col-md-8'>
															<div className='d-flex justify-content-start py-2'>
																{
																	e.quantity <= 1
																	? <button className='btn border-0 p-0' type='button' disabled><FontAwesomeIcon icon={faMinusCircle} color="red" style={{fontSize: '20px'}}/></button>
																	: <button className='btn border-0 p-0' type='button' onClick={() => countItem(e, -1)}><FontAwesomeIcon icon={faMinusCircle} color="red" style={{fontSize: '20px'}}/></button>
																}
																	<span className='bg-light-subtle border border-light-subtle rounded px-3 mx-2'>{e.quantity}</span>
																{
																	e.quantity >= 10
																	? <button className='btn border-0 p-0' type='button' disabled><FontAwesomeIcon icon={faPlusCircle} color="blue" style={{fontSize: '20px'}}/></button>
																	: <button className='btn border-0 p-0' type='button' onClick={() => countItem(e, 1)}><FontAwesomeIcon icon={faPlusCircle} color="blue" style={{fontSize: '20px'}}/></button>
																}
																
															</div>
														</div>
														<div className="col-md-4">
															<button className='btn border-0 p-0' type='button' onClick={() => confirmDeleteItem(e)}>
																<FontAwesomeIcon icon={faTrashCan} className="text-dark-emphasis" style={{fontSize: '20px'}}/>
															</button>													
														</div>
													</div>
												</div>
											</li>
										})
									}
									<li className='px-3'>
										{
											cart.length <= 0
											? 	<div className='text-center'>
													<span className='text-center'>{t('header.cart.empty')}</span>
												</div>
											: 	<button className='btn btn-bd-primary btn-sm btn-shadow btn-block w-100 fw-bold' onClick={() => navigate('/checkout')}>
													<span>{t('header.cart.gotocheckout')}</span>
												</button>
										}
										
									</li>
								</ul>

							</div>

							{
								token === null
								?	<Link to="/login" className="btn btn-sm btn-bd-primary me-3 mb-2" style={{width: 105}}>
										<span>
											{t('header.login')} 
										</span>
									</Link>
								:	<div className="dropdown me-3 mb-2">

										<a className="btn btn-sm btn-outline-primary dropdown-toggle" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{width: 120}}>
											<FontAwesomeIcon icon={faAddressCard} className="me-1"/>
											<span>
												{t('header.my_account')}
												{
													// tokenDecode?.email !== null
													// ?	tokenDecode?.email
													// :	'My account'
													// tokenDecode?.email || '-'
												}
											</span>	
										</a>
					
										{/* <ul className="dropdown-menu" style={{width: '100%'}}> */}
										<ul className="dropdown-menu" style={{width: '250px'}}>
											<li>
												<a className="dropdown-item disabled">
													{
														tokenDecode?.email || '-'
													}
												</a>
											</li>

											<li><hr className="dropdown-divider"/></li>
											<li>
												<Link className="dropdown-item" to="dashboard">Portal Labeldictate</Link>
											</li>
											<li>
												<a className="dropdown-item" href="#!" onClick={() => logout()}>{t('header.logout')}</a>
											</li>
										</ul>
									</div> 
							}
							
							<div className='d-flex flex-column'>

								<div className="btn-group me-3 mb-2" style={{width:120}}>						
									<button type="button" className="btn btn-outline-danger btn-sm dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"  aria-expanded="false">
										{
											i18n.language === 'en'
											?<span className="fi fi-us"></span> 
											:<span className="fi fi-mx"></span> 
										}								
										<span className='ms-1'>
											{t('header.language')}
										</span>
									</button>
									<ul className="dropdown-menu dropdown-menu-end w-50">
										<li>
											<button className="dropdown-item" type="button" onClick={()=> i18n.changeLanguage('en')} >
												<span className="fi fi-us"></span> 
												<span className='ms-1'>
													English
												</span>
											</button>
										</li>
										<li>
											<button className="dropdown-item" type="button" onClick={()=> i18n.changeLanguage('es')} >
												<span className="fi fi-mx"></span> 
												<span className='ms-1'>
													Español
												</span>
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className='me-2'>
							<a href="#!" onClick={() => darkMode()} className="form-check-label pointer" htmlFor="flexSwitchCheckChecked">
								<FontAwesomeIcon icon={darkmode ? faMoon : faSun} style={{marginLeft: '5px'}}/>
							</a>
						</div>

					</div>
				</div>
			</nav>
			<BackButton/>
			<Outlet /> {/* This is main element */}
			<Footer/>
		</div>

	</>
  )
}

export default Header